import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import ListSubheader from '@material-ui/core/ListSubheader';
import {isMobile} from 'react-device-detect';

const useStyles = makeStyles((theme) => ({
      gridList: {
        width: '100vw',
      },
      title: {
        fontWeight: 'normal',
        fontSize: '25px',
        paddingLeft: 5,
        color: '#7E1326',
        marginBottom: 7,
      },
      subtitle: {
        fontWeight: 'normal',
        paddingLeft: 5,
        color: '#585858',
        lineHeight: '15px',
        marginBottom: '10px'
      },
      imgSize: {
        height: '100%',
        width: '100%',
        display: 'flex',
        position: 'absolute',
        alignItems: 'center',
        justifyContent: 'center',
        bottom: 0,
        textAlign: 'center',
      },
      isVideo: {
        width: '5vw',
      },
      isSold: {
        width: '15vw',
      }
}));

export default function GaleriaPreview(props) {
  const classes = useStyles();

  let columns = isMobile? 2 : 4;
  let isSold = props.data.status === "VENDIDO" ? <img className={classes.isSold} src="/static/page/vendido.png" alt="video" /> : undefined;
  let subtitle = props.data.descripcion ? props.data.descripcion : props.data.description ? props.data.description : props.data.number ? `Número: ${props.data.number}` : undefined;
  let details = props.data.weight && props.data.birthdate ? `, Peso: ${props.data.weight} kg, Nacimiento: ${props.data.birthdate}` : "";
  let compSubTitle;
  if (subtitle !== undefined) {
    compSubTitle = <ListSubheader component="div" className={classes.subtitle}>{subtitle}{details}</ListSubheader>;
  }
  
  return (
      <GridList cols={columns} className={classes.gridList}>
        <GridListTile key="Subheader" cols={columns} style={{ height: 'auto' }}>
            <ListSubheader component="div" className={classes.title}>{`${props.data.title ? props.data.title : props.data.name}`}</ListSubheader>
            {compSubTitle}
        </GridListTile>
        {props.data.item.map((item, index) => {
            let imagenUrl = item.url === undefined
            ? "/static/images/wireframe/square-image.png"
            : item.type === "IMG"
            ? `https://cdn.rancholasardillas.com/multimedia/${item.url}`
            : `https://img.youtube.com/vi/${item.url}/hqdefault.jpg`;
            let hasMaker = isSold !== undefined ? isSold : item.type === "VID" ? <img className={classes.isVideo} src="/static/page/youtube.png" alt="video" /> : undefined;
            return (
            <GridListTile key={imagenUrl}>
              <img src={imagenUrl} alt={item.title} />   
              <div className={classes.imgSize} onClick={() => props.handleClickOpen(props.data, index)}>{hasMaker}</div>
              <GridListTileBar title={item.title} />
            </GridListTile>
        )
        })}
      </GridList>
  );
}