import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Carousel from "nuka-carousel";

const useStyles = makeStyles((theme) => ({
  itemMultimedia: {
    height: "100vh",
    overflow: "hidden",
    position: "relative",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  imageCard: {
    height: "100%",
    position: "relative",
  },
  videoCard: {
    height: "100%",
    width: "100%",
    position: "relative",
  },
  textCard: {
    fontSize: "1.5rem",
    color: "#FFF",
    left: 0,
    right: 0,
    top: 0,
    height: "7vh",
    background: "rgba(0, 0, 0, 0.5)",
    alignItems: "center",
    display: "flex",
    position: "absolute",
    justifyContent: "center",
  },
}));

export default function Multimedia(props) {
  const classes = useStyles();

  function Item({ item }) {
    let preview;
    let previewTitle;
    if (item.type === "VID") {
      let src = `https://www.youtube.com/embed/${item.url}?rel=0`;
      preview = (
        <iframe
          src={src}
          title={item.title}
          frameBorder='0'
          className={classes.videoCard}
          allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
        />
      );
    }
    if (item.type === "IMG") {
      preview = (
        <img
          src={`https://cdn.rancholasardillas.com/multimedia/${item.url}`}
          className={classes.imageCard}
          alt={item.title}
        />
      );
      previewTitle = (
        <div className={classes.textCard}>{item.title}</div>
      );
    }
    return (
      <div className={classes.itemMultimedia}>
        {preview}
        {previewTitle}
      </div>
    );
  }

  return (
    <React.Fragment>
      <Carousel
        autoplay={false}
        slideIndex={props.selected}
        defaultControlsConfig={{
          nextButtonText: "Siguiente",
          prevButtonText: "Anterior",
        }}>
        {props.data.item.map((it) => (
          <Item item={it} key={it.id} />
        ))}
      </Carousel>
    </React.Fragment>
  );
}
