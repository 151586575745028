import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Home from "../page/home/Home";
import Galeria from "../page/galeria/Galeria";
import Genetics from "../page/genetics/Genetics";
import Kangal from "../page/kangal/Kangal";
import Campeonato from "../page/campeonato/Campeonato";
import Specimens from "../page/specimens/Specimens";
import Stallion from "../page/stallion/Stallion";
import { NoMatch } from "../page/nomatch/NoMatch";

import ProductHero from "../section/ProductHero";
import AppFooter from "../section/AppFooter";

export default function Main() {
  return (
    <React.Fragment>
      <BrowserRouter>
        <ProductHero />
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/galeria' component={Galeria} />
          <Route exact path='/genetica' component={Genetics} />
          <Route exact path='/kangal' component={Kangal} />
          <Route exact path='/campeonato' component={Campeonato} />
          <Route exact path='/venta' component={Specimens} />
          <Route exact path='/semental' component={Stallion} />
          <Route component={NoMatch} />
        </Switch>
        <AppFooter />
      </BrowserRouter>
    </React.Fragment>
  );
}
