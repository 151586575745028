import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import LocationOnIcon from '@material-ui/icons/LocationOn';

const pingStyle = {
  fontSize: '3rem'
};

const AnyReactComponent = ()  => <LocationOnIcon style={pingStyle} onClick={() => window.open('https://goo.gl/maps/pFJcdGRD5CPX2S1w8', '_blank')} />;

class GoogleMapsContainer extends Component {
  static defaultProps = {
    center: {
      lat: 21.9447683,
      lng: -102.1933836
    },
    zoom: 11
  };

  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '50vh', width: '100%', textAlign: 'center', color: '#7e1326'}}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyD7b1rKPXcyb59cjuNtHCx3AAC3-odNVQg'}}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <AnyReactComponent
            lat={21.9447683}
            lng={-102.1933836}
          />
        </GoogleMapReact>
       <div style={{ marginTop: '1vh', fonSize: '14px', fontFamily: '"Work Sans", sans-serif'}}> Haz click en el pin para ver ubicación</div>
      </div>
    );
  }
}

export default GoogleMapsContainer;