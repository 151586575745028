import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import ProductHeroLayout from './ProductHeroLayout';

const styles = (theme) => ({
  h4_title: {
    font: "65px/70px 'Open Sans', sans-serif",
    textTransform: 'inherit',
    color: '#7e1326',
  },
  h5_title: {
    color: '#82712c',
    font: "normal 35px/60px 'Open Sans', sans-serif",
  },
  h5_descr: {
    color: '#7e1326',
    font: "normal 20px/60px 'Open Sans', sans-serif",
  },
  background: {}
});

function ProductHero(props) {
  const { classes } = props;

  return (
    <ProductHeroLayout backgroundClassName={classes.background}>
      <Typography align="center" variant="h4" marked="center" className={classes.h4_title}>
        La Mejor Genética Sudafricana
      </Typography>
      <Typography align="center" variant="h5" marked="center" className={classes.h5_title}>
        Sementales, Hembras Dorper y Boer Full Blood
      </Typography>
      <Typography align="center" variant="h5" marked="center" className={classes.h5_descr}>
        HATO LIBRE DE BRUCELOSIS Y BRUCELA OVIS
      </Typography>
    </ProductHeroLayout>
  );
}

ProductHero.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductHero);
