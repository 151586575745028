import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { Typography } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import Avatar from '@material-ui/core/Avatar';
import CheckIcon from '@material-ui/icons/Check';

const styles = (theme) => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
  },
  container: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    backgroundColor: '#ebebeb',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 2),
  },
  image: {
    width: '25vw',
    [theme.breakpoints.down('sm')]: {
      width: '95vw',
    },
  },
  parra_rancho:{
    marginTop: theme.spacing(5)
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(2),
    textAlign: 'center',
    color: '#7E1326',
    fontSize: '25px',
  },
  title1: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    textAlign: 'center',
    color: '#7E1326',
    fontFamily: "'Roboto Condensed', sans-serif",

  },
  title2: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5),
    textAlign: 'center',
    color: '#7E1326',
    fontFamily: "'Roboto Condensed', sans-serif",
  },
  curvyLines: {
    pointerEvents: 'none',
    position: 'absolute',
    top: -180,
  },
  list: {
    width: '100%',
    padding: 0,
  },
  listItem: {
    paddingTop: 0,
  },
  gridContainer: {
    marginBottom: theme.spacing(1),
  },
  accordionTopFull: {
    marginTop: theme.spacing(3),
    width: '100%',
  },
  accordionFull: {
    width: '100%',
  },
  accordionDetails: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  bullet:{
    width: '1vw',
    height: '1vw',
    [theme.breakpoints.down('sm')]: {
      width: '2vh',
      height: '2vh',
    },
  },
  checkColor:{
    color: '#7E1326',
  },
  checkColumn:{
    width: '3vw',
    minWidth: '3vw',
    [theme.breakpoints.down('sm')]: {
      width: '8vw',
      height: '8vw',
    },
  },
  hembrasTitle: {
    fontWeight:'bold',
  },
  hembrasFoot:{
    fontWeight:'bold',
    color: '#7E1326'
  },
  kangalSection: {
    marginTop: '5vh',
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  kangalTitle:{
    color: '#7E1326',
    fontSize: '25px',
    textAlign: 'center',
    marginBottom: '16px',
  },
  kangalText:{
    textAlign: 'center',
    fontSize: '16px',
    fontFamily: "'Work Sans', sans-serif",
    fontWeight: '400',
    lineHeight: '1.43',
  },
  kangalImages:{
    textAlign: 'center',
  },
  kangalImage:{
    width: '15vw',
    padding: '15px',
    [theme.breakpoints.down('sm')]: {
      width: '45vw',
    },
  }

});

function ProductValues(props) {
  const { classes } = props;

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        <Typography variant="h4" className={classes.title1}>
          SI ESTAS BUSCANDO...
        </Typography>
        <Grid container spacing={3} className={classes.gridContainer}>
          <Grid item xs={12} md={4}>
            <List className={classes.list}>
              <ListItem className={classes.listItem}>
                <ListItemAvatar className={classes.checkColumn}>
                  <Avatar src="/static/page/bullet.png" className={classes.bullet}  />
                </ListItemAvatar>
                <ListItemText
                  primary="Iniciar tu rebaño con ejemplares de excelente calidad."
                />
              </ListItem>
              <ListItem>
                <ListItemAvatar className={classes.checkColumn}>
                  <Avatar src="/static/page/bullet.png" className={classes.bullet}  />
                </ListItemAvatar>
                <ListItemText
                  primary="Incrementar la producción de tu rebaño."
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} md={4}>
            <List className={classes.list}>
              <ListItem className={classes.listItem}>
                <ListItemAvatar className={classes.checkColumn}>
                  <Avatar src="/static/page/bullet.png" className={classes.bullet}  />
                </ListItemAvatar>
                <ListItemText
                  primary="Mejorar la conversión de alimento a carne."
                />
              </ListItem>
              <ListItem>
                <ListItemAvatar className={classes.checkColumn}>
                <Avatar src="/static/page/bullet.png" className={classes.bullet}  />
                </ListItemAvatar>
                <ListItemText
                  primary="Disminuir los días de engorda."
                />
              </ListItem>
            </List>
          </Grid>

          <Grid item xs={12} md={4}>
            <List className={classes.list}>
              <ListItem className={classes.listItem}>
                <ListItemAvatar className={classes.checkColumn}>
                <Avatar src="/static/page/bullet.png" className={classes.bullet}  />
                </ListItemAvatar>
                <ListItemText
                  primary="Incrementar los pesos al nacimiento y destete."
                />
              </ListItem>
              <ListItem>
                <ListItemAvatar className={classes.checkColumn}>
                <Avatar src="/static/page/bullet.png" className={classes.bullet}  />
                </ListItemAvatar>
                <ListItemText
                  primary="Mejorar el aprovechamiento de tus praderas."
                />
              </ListItem>
            </List>
          </Grid>
        </Grid>
        <Typography variant="h4" className={classes.title2}>
        SOMOS TU MEJOR OPCIÓN
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <div className={classes.item}>
              <img
                className={classes.image}
                src="/static/page/dorper.png"
                alt="graph"
              />
              <Typography variant="h6" className={classes.title}>
                LA RAZA DORPER
              </Typography>
              <Typography align='justify'>
                Proviene de Sudrafica, fue creada alrededor de 1942 y está conformada por una combinación de Raza Dorset Horn (macho) X Blackhead Persian (hembra), que producían la mayoría de las propiedades deseadas arriba del nivel de media sangre.
                Existe dentro de la Raza Dorper, la raza Dorper Blanco, criada a partir de la cruza de Dorset Horn X Persian y Dorset Horn X Cruza Van Rooy.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.item}>
              <img
                className={classes.image}
                src="/static/page/boer.png"
                alt="graph"
              />
              <Typography variant="h6" className={classes.title}>
                LA RAZA BOER
              </Typography>
              <Typography align='justify'>
                Proviene de Sudáfrica, fue creada a principios del siglo XX y está conformada por una combinación de cruzas de cabras nativas con cabras lecheras Europeas.
                La cabra Boer es compacta, pero bien proporcionada, pelo corto y músculos bien desarrollados.
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={3} className={classes.parra_rancho}>
          <Grid item xs={12} md={6}>
            <div className={classes.item}>
              <img
                className={classes.image}
                src="/static/page/dorper_macho.png"
                alt="graph"
              />
              <Typography variant="h6" className={classes.title}>
                SEMENTALES
              </Typography>
              <Typography className={classes.hembrasTitle}>
                Nuestros sementales tienen la garantía de:
                  </Typography>
              <List>
                <ListItem>
                  <ListItemIcon><CheckIcon className={classes.checkColor} /></ListItemIcon>
                  <ListItemText primary="Ejemplares 100% puro Full Blood" />
                </ListItem>
                <ListItem>
                  <ListItemIcon><CheckIcon className={classes.checkColor} /></ListItemIcon>
                  <ListItemText primary="Excelente fertilidad" />
                </ListItem>
                <ListItem>
                  <ListItemIcon><CheckIcon className={classes.checkColor} /></ListItemIcon>
                  <ListItemText primary="Excelente capacidad de cobertura para empadrar" />
                </ListItem>
                <ListItem>
                  <ListItemIcon><CheckIcon className={classes.checkColor} /></ListItemIcon>
                  <ListItemText primary="Excelente como terminal para cualquier raza de ovejas" />
                </ListItem>
                <ListItem>
                  <ListItemIcon><CheckIcon className={classes.checkColor} /></ListItemIcon>
                  <ListItemText primary="Fácil y económico mantenimiento" />
                </ListItem>
                <ListItem>
                  <ListItemIcon><CheckIcon className={classes.checkColor} /></ListItemIcon>
                  <ListItemText primary="Su descendencia tendrá muy buenas ganancias cárnicas, con resultados positivos desde el nacimiento de los primeros corderos" />
                </ListItem>
              </List>
              <Typography className={classes.hembrasFoot}>
              Lo necesario para asegurar tu producción.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.item}>
              <img
                className={classes.image}
                src="/static/page/boer_macho.png"
                alt="graph"
              />
              <Typography variant="h6" className={classes.title}>
                SEMENTALES
              </Typography>

              <Typography className={classes.hembrasTitle}>
                Nuestros sementales tienen la garantía de:
                  </Typography>
              <List>
                <ListItem>
                  <ListItemIcon><CheckIcon className={classes.checkColor} /></ListItemIcon>
                  <ListItemText primary="Ejemplares 100% puro Full Blood" />
                </ListItem>
                <ListItem>
                  <ListItemIcon><CheckIcon className={classes.checkColor} /></ListItemIcon>
                  <ListItemText primary="Excelente fertilidad" />
                </ListItem>
                <ListItem>
                  <ListItemIcon><CheckIcon className={classes.checkColor} /></ListItemIcon>
                  <ListItemText primary="Excelente capacidad de cobertura para empadrar" />
                </ListItem>
                <ListItem>
                  <ListItemIcon><CheckIcon className={classes.checkColor} /></ListItemIcon>
                  <ListItemText primary="Excelente como terminal para cualquier raza de cabras" />
                </ListItem>
                <ListItem>
                  <ListItemIcon><CheckIcon className={classes.checkColor} /></ListItemIcon>
                  <ListItemText primary="Fácil y económico mantenimiento" />
                </ListItem>
                <ListItem>
                  <ListItemIcon><CheckIcon className={classes.checkColor} /></ListItemIcon>
                  <ListItemText primary="Su descendencia tendrá muy buenas ganancias cárnicas, con resultados positivos desde el nacimiento de los primeros cabritos" />
                </ListItem>
              </List>
              <Typography className={classes.hembrasFoot}>
              Lo necesario para asegurar tu producción.
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={3} className={classes.parra_rancho} >
          <Grid item xs={12} md={6}>
            <div className={classes.item}>
              <img
                className={classes.image}
                src="/static/page/dorper_hembra.png"
                alt="graph"
              />
              <Typography variant="h6" className={classes.title}>
                HEMBRAS
              </Typography>

              <Typography className={classes.hembrasTitle}>
                Nuestras hembras tienen la garantía de:
              </Typography>
              <List>
                <ListItem>
                  <ListItemIcon><CheckIcon className={classes.checkColor} /></ListItemIcon>
                  <ListItemText primary="Ejemplares 100% puro Full Blood" />
                </ListItem>
                <ListItem>
                  <ListItemIcon><CheckIcon className={classes.checkColor} /></ListItemIcon>
                  <ListItemText primary="Extremadamente fértiles" />
                </ListItem>
                <ListItem>
                  <ListItemIcon><CheckIcon className={classes.checkColor} /></ListItemIcon>
                  <ListItemText primary="Excelente maternidad" />
                </ListItem>
                <ListItem>
                  <ListItemIcon><CheckIcon className={classes.checkColor} /></ListItemIcon>
                  <ListItemText primary="Facilidad de parto" />
                </ListItem>
                <ListItem>
                  <ListItemIcon><CheckIcon className={classes.checkColor} /></ListItemIcon>
                  <ListItemText primary="Alta producción de leche" />
                </ListItem>
                <ListItem>
                  <ListItemIcon><CheckIcon className={classes.checkColor} /></ListItemIcon>
                  <ListItemText primary="Partos gemelares y en ocasiones triples" />
                </ListItem>
                <ListItem>
                  <ListItemIcon><CheckIcon className={classes.checkColor} /></ListItemIcon>
                  <ListItemText primary="Paren corderos fuertes y vigorosos" />
                </ListItem>
                <ListItem>
                  <ListItemIcon><CheckIcon className={classes.checkColor} /></ListItemIcon>
                  <ListItemText primary="Con altas tasas de crecimiento temprano" />
                </ListItem>
                <ListItem>
                  <ListItemIcon><CheckIcon className={classes.checkColor} /></ListItemIcon>
                  <ListItemText primary="Muy bajo índice de mortandad" />
                </ListItem>
                <ListItem>
                  <ListItemIcon><CheckIcon className={classes.checkColor} /></ListItemIcon>
                  <ListItemText primary="Excelente temperamento" />
                </ListItem>
                <ListItem>
                  <ListItemIcon><CheckIcon className={classes.checkColor} /></ListItemIcon>
                  <ListItemText primary="Fácil y económico mantenimiento" />
                </ListItem>
              </List>
              <Typography className={classes.hembrasFoot}>
              La raza Dorper se adapta a cualquier tipo de clima.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.item}>
              <img
                className={classes.image}
                src="/static/page/boer_hembra.png"
                alt="graph"
              />
              <Typography variant="h6" className={classes.title}>
                HEMBRAS
              </Typography>

              <Typography className={classes.hembrasTitle}>
                Nuestras hembras tienen la garantía de:
                  </Typography>
              <List>
                <ListItem>
                  <ListItemIcon><CheckIcon className={classes.checkColor} /></ListItemIcon>
                  <ListItemText primary="Ejemplares 100% puro Full Blood" />
                </ListItem>
                <ListItem>
                  <ListItemIcon><CheckIcon className={classes.checkColor} /></ListItemIcon>
                  <ListItemText primary="Extremadamente fértiles" />
                </ListItem>
                <ListItem>
                  <ListItemIcon><CheckIcon className={classes.checkColor} /></ListItemIcon>
                  <ListItemText primary="Excelente maternidad" />
                </ListItem>
                <ListItem>
                  <ListItemIcon><CheckIcon className={classes.checkColor} /></ListItemIcon>
                  <ListItemText primary="Facilidad de parto" />
                </ListItem>
                <ListItem>
                  <ListItemIcon><CheckIcon className={classes.checkColor} /></ListItemIcon>
                  <ListItemText primary="Alta producción de leche" />
                </ListItem>
                <ListItem>
                  <ListItemIcon><CheckIcon className={classes.checkColor} /></ListItemIcon>
                  <ListItemText primary="Partos gemelares y en ocasiones triples" />
                </ListItem>
                <ListItem>
                  <ListItemIcon><CheckIcon className={classes.checkColor} /></ListItemIcon>
                  <ListItemText primary="Paren cabritos fuertes y vigorosos" />
                </ListItem>
                <ListItem>
                  <ListItemIcon><CheckIcon className={classes.checkColor} /></ListItemIcon>
                  <ListItemText primary="Con altas tasas de crecimiento temprano" />
                </ListItem>
                <ListItem>
                  <ListItemIcon><CheckIcon className={classes.checkColor} /></ListItemIcon>
                  <ListItemText primary="Muy bajo índice de mortandad" />
                </ListItem>
                <ListItem>
                  <ListItemIcon><CheckIcon className={classes.checkColor} /></ListItemIcon>
                  <ListItemText primary="Excelente temperamento" />
                </ListItem>
                <ListItem>
                  <ListItemIcon><CheckIcon className={classes.checkColor} /></ListItemIcon>
                  <ListItemText primary="Fácil y económico mantenimiento" />
                </ListItem>
              </List>
              <Typography className={classes.hembrasFoot}>
              La raza Boer se adapta a cualquier tipo de clima.
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={10} className={classes.gridContainer}>
          <div className={classes.kangalSection}>
            <h2 className={classes.kangalTitle}>KANGAL TURCO</h2>
            <p className={classes.kangalText}>Lo mejor para la guarda y protección de ganado, estas particularidades son propias de la raza, así que no requiere entrenamiento.</p>
            <div className={classes.kangalImages}>
              <img
                className={classes.kangalImage}
                src="/static/page/kangal1.png"
                alt="graph"
              />
              <img
                className={classes.kangalImage}
                src="/static/page/kangal2.png"
                alt="graph"
              />
              <img
                className={classes.kangalImage}
                src="/static/page/kangal3.png"
                alt="graph"
              />
              <img
                className={classes.kangalImage}
                src="/static/page/kangal4.png"
                alt="graph"
              />
            </div>
          </div>
        </Grid>
      </Container>
    </section>
  );
}

ProductValues.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductValues);
