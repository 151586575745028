import React, { useState, useEffect } from "react";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import RelatedBrands from '../../section/RelatedBrands';

import ProductCategoriesSmall from '../../section/ProductCategoriesSmall';
import GaleriaPreview from '../../section/GaleriaPreview';
import Multimedia from "../../section/Multimedia";

import ranchoApi from "../../../api/rancho";

const useStyles = makeStyles((theme) => ({
  heroContent: {
    padding: theme.spacing(8, 0, 0),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: "#FFF",
  },
  backgroundRancho: {
    marginTop: '5vh',
    backgroundColor:  '#ebebeb'
  },
  titleSection: {
    fontWeight: '700',
    color: '#7E1326',
    fontSize: '30px',
    marginTop: '40px',
    textAlign: 'center',
    marginBottom: '16px',
    fontFamily: "'Roboto Condensed', sans-serif",
    lineHeight: '1.6',
    textTransform: 'uppercase',
  },
  galeriaPreview: {
    marginBottom: '10vh'
  }
}));

export default function Specimens() {
  const classes = useStyles();

  const [data, setData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [element, setElement] = useState({});
  const [selected, setSelected] = React.useState(0);

  const handleClickOpen = (element, selected) => {
    setElement(element);
    setSelected(selected);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    let mergedata = [];
    ranchoApi
      .get("/specimens?type=dorper,boer&stallion=false")
      .then((res) => {
        let promises = [];
        res.data.forEach((it) => {
          promises.push(
            ranchoApi
              .get(`/multimedia?type_reference=specimen&id_reference=${it.id}`)
              .then(mm => {    
                mergedata = [...mergedata, {...it, item: mm.data}];
              }).catch((error) => console.log(error))
          );
        });
        Promise.all(promises).then(() => {
            mergedata.sort((a, b) => (a.idx > b.idx) ? 1 : (a.idx === b.idx) ? ((a.size > b.size) ? 1 : -1) : -1 );
            setData(mergedata);
        });
      })
      .catch((error) => {
        console.log(`Error ${error}`);
      });
  }, []);

  return (
    <div className={classes.backgroundRancho}>
      <ProductCategoriesSmall />
      <div className={classes.heroContent}>
        <Container maxWidth='md'>
          <Typography
            component='h2'
            variant='h3'
            align='center'
            color='textPrimary'
            className={classes.titleSection} 
            gutterBottom>
            Ejemplares en Venta
          </Typography>
        </Container>
      </div>
      <Container className={classes.cardGrid} maxWidth='md'>
      {data.map((element) => (
          <Grid  key={element.id} container spacing={4} className={classes.galeriaPreview}>
            <GaleriaPreview key={element.id} data={element} handleClickOpen={handleClickOpen}/>
          </Grid>
        ))}
        <Dialog fullScreen open={open} onClose={handleClose}>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge='start'
                color='inherit'
                onClick={handleClose}
                aria-label='close'>
                <CloseIcon />
              </IconButton>
              <Typography variant='h6' className={classes.title}>
                {element.title}
              </Typography>
            </Toolbar>
          </AppBar>
          <Multimedia data={element} selected={selected}/>
        </Dialog>
      </Container>
      <RelatedBrands />
    </div>
  );
}
