import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: theme.spacing(10),
    paddingTop: theme.spacing(10),
    backgroundColor: '#ebebeb',
  },
  button: {
    border: '4px solid currentColor',
    borderRadius: 0,
    height: 'auto',
    padding: theme.spacing(2, 5),
  },
  link: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  buoy: {
    width: 60,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  logo: {
    height: '10vh',
    [theme.breakpoints.down('sm')]: {
      height: '11vh',
    },
  },
  gridItem: {
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '5vh',
    },
    maxWidth: '100%',
  },
  container: {
    marginTop: theme.spacing(8),
    display: 'flex',
  },
  socials:{
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    '& img': {
      height: '5vh',
      marginLeft: '4vw',
      [theme.breakpoints.down('sm')]: {
        heigth: '5vh',
      },
    },
  },
  socialsYt:{
  }
});

function RelatedBrands(props) {
  const { classes } = props;

  return (
    <Container className={classes.root} component="section">
     <Grid container direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid item md={2} xs={12} className={classes.gridItem}>
          <a rel="noopener noreferrer" href="https://www.facebook.com/asociaciondeovinosags" target="_blank">
            <img
              className={classes.logo}
              src="/static/page/agloyca.png"
              alt="AGLOYCA"
            />
          </a>               
        </Grid>
        <Grid item md={2} xs={12} className={classes.gridItem}>
          <a rel="noopener noreferrer" href="https://www.uno.org.mx/" target="_blank">
            <img
              className={classes.logo}
              src="/static/page/uno.png"
              alt="UNO"
            />
          </a>  
        </Grid>
        <Grid item md={2} xs={12} className={classes.gridItem}>
            <img
              className={classes.logo}
              src="/static/page/card.png"
              alt="Tarjetas"
            />
        </Grid>
        <Grid item md={2} xs={12} className={classes.gridItem}>
          <a rel="noopener noreferrer" href="https://dorpersa.co.za/" target="_blank">
            <img
              className={classes.logo}
              src="/static/page/dorperlogo.png"
              alt="DORPER"
            />
          </a>  
        </Grid>
        <Grid item md={2} xs={12} className={classes.gridItem}>
          <a rel="noopener noreferrer" href="https://asociacionmexicanadecaprinos.com/" target="_blank">
            <img
              className={classes.logo}
              src="/static/page/sistema.png"
              alt="Sistema"
            />
          </a>  
        </Grid>
      </Grid>
      <Grid container className={classes.container}>
          <Grid item md={12} className={classes.socials}>
            <a href="https://t.me/rancholasardillas" target="_bank">
              <img src="/static/page/telegrama.png" alt="Telegrama" />
            </a>
            <a href="https://www.facebook.com/rancholasardillas" target="_bank">
              <img src="/static/page/facebook.png" alt="Facebook" />
            </a>
            <a href="https://www.instagram.com/rancholasardillas/" target="_bank">
              <img src="/static/page/instagram.png" alt="Youtube" className={classes.socialsYt}/>
            </a>
            <a href="https://www.youtube.com/channel/UCmw-_AMza5vvzMlv1nirBHA" target="_bank">
              <img src="/static/page/youtube.png" alt="Youtube" className={classes.socialsYt}/>
            </a>
          </Grid>
        </Grid>
    </Container>
  );
}

RelatedBrands.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RelatedBrands);