import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

const logo_desktop =
'/static/page/header_desktop.png';
const logo_mobile =
'/static/page/header_mobile.png';


const styles = (theme) => ({
  root: {
    color: theme.palette.common.white,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
   },
   container: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
   },
  logo: {
    width: '80vw',
    maxWidth: '80vw',
    content: `url(${logo_desktop})`,
    [theme.breakpoints.down('sm')]: {
      content: `url(${logo_mobile})`,
    },
  },
});

function ProductHeroLayout(props) {
  const { backgroundClassName, children, classes } = props;


  return (
    <React.Fragment>

    <section>
      <Container className={classes.container_ppal}>
      {children}
      </Container>
    </section>
    <section className={classes.root}>
      <Container className={classes.container}>
        <Link to="/"><img
          className={classes.logo}
          alt="logo"
        /></Link>
        <div className={clsx(classes.background, backgroundClassName)} />
      </Container>
    </section>
    </React.Fragment>
  );
}

ProductHeroLayout.propTypes = {
  backgroundClassName: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductHeroLayout);
