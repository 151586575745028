import React, {useState} from 'react'
import PropTypes from 'prop-types'
import Container from '@material-ui/core/Container'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import Button from '@material-ui/core/Button'
import Alert from '@material-ui/lab/Alert';
import { Typography } from '@material-ui/core';

import { InputLabel, Input, TextareaAutosize} from '@material-ui/core'

import ranchoApi from '../../api/rancho';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: theme.spacing(9),
    paddingBottom: theme.spacing(9),
    backgroundColor: "#fff",
    maxWidth: 'none'
  },
  gridItem: {
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '5vh',
    },
  },
  contactForm: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(3),
  },
  contactField: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  titleContacto:{
    textAlign: 'left',
    marginLeft: '7vw',
  },
  titleContacto2:{
    textAlign: 'left',
  },
  contactBtn:{
    width: '100%',
    marginTop: theme.spacing(2),
    backgroundColor: '#7e1326',
  },
  telefono:{
    fontSize: '20px',
  },
  alingtextrancho: {
    fontSize: '20px',
    textAlign: 'left',
    paddingLeft: '7vw'
  },
  alingtextranchotel: {
    fontSize: '30px',
    textAlign: 'left',
    paddingLeft: '7vw'
  },
  alingtextranchotel3: {
    textAlign: 'left',
    paddingLeft: '7vw'
  },
  rowRancho: {
    gridItem: 'initial'
  }

})

function ContactForm(props) {
  const { classes } = props;
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [message, setMessage] = useState('')
  const [iserror, setIserror] = useState(false)
  const [errorMessages, setErrorMessages] = useState([])

  let onEmailChange = (e) => setEmail(e.target.value);

  let onNameChange = (e) =>  setName(e.target.value);

  let onPhoneChange = (e) => setPhone(e.target.value);

  let onMessageChange = (e) => setMessage(e.target.value);

  let showAndClean = (message) => {
    setErrorMessages(message)
    setIserror(true)
    setTimeout(() => {  
      setIserror(false)
      setErrorMessages([])
    }, 5000);
  }
  
  async function handleSubmit(event) {
    event.preventDefault();
    let errorList = []
    if(email === ""){
      errorList.push("Porfavor ingresa un email")
    }
    if(name === ""){
      errorList.push("Porfavor ingresa una nombre")
    }
    if(message === ""){
      errorList.push("Porfavor ingresa un mensaje")
    }
    if(errorList.length < 1){
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        await ranchoApi.post("/util/email", {email, name, phone, message}, {headers})
        .then(res => {
            showAndClean([`Mensaje Enviado!`])
            setEmail('')
            setName('')
            setPhone('')
            setMessage('')
        })
        .catch(err => {
          showAndClean([`Datos de contacto invalidos`])
        })

    }else{
      showAndClean(errorList)
    }
  };

  return (
    <Container className={classes.root}>
     <Grid container direction="row"
      >
        <Grid item md={6} xs={12} className={classes.gridItem}>
            <Typography variant="h6" className={classes.titleContacto2}>
                Contáctanos
            </Typography> 
            <form className={classes.contactForm} onSubmit={handleSubmit} noValidate>   
                <FormControl className={classes.contactField}>
                    <InputLabel htmlFor="my-input">Nombre</InputLabel>
                    <Input id="name" aria-describedby="my-helper-text" value={name} onChange={onNameChange} />
                </FormControl>
                <FormControl className={classes.contactField}>
                    <InputLabel htmlFor="my-input">Teléfono</InputLabel>
                    <Input id="phone" aria-describedby="my-helper-text" value={phone} onChange={onPhoneChange}/>
                </FormControl>
                <FormControl className={classes.contactField}>
                    <InputLabel htmlFor="my-input">Email</InputLabel>
                    <Input id="email" aria-describedby="my-helper-text" value={email} onChange={onEmailChange} />
                </FormControl>
                <FormControl className={classes.contactField}>
                    <TextareaAutosize id="message" aria-label="minimum height" rowsMin={10} value={message} onChange={onMessageChange} />
                </FormControl>
                <Button variant="contained" color="primary" type="submit" className={classes.contactBtn}>
                    Enviar Mensaje
                </Button>
            </form>
            {iserror && 
              <Alert severity="error">
                  {errorMessages.map((msg, i) => {
                      return <div key={i}>{msg}</div>
                  })}
              </Alert>
            } 
        </Grid>
        <Grid item md={6} xs={12} className={classes.gridItem}>
        <Typography variant="h6" className={classes.titleContacto}>
                Dirección
            </Typography>
        <div className="row justify-content-right">
          <div className="col-md-8 mx-auto contact-form-contact-info">
              <p className={classes.alingtextranchotel3}><span className="ion-ios-location icon mr-5"></span>Carr. Km 1.5 a San Antonio de Los Pedroza</p>
              <p className={classes.alingtextranchotel3}><span className="ion-ios-location icon mr-5"></span>C.P. 20363. Aguascalientes, Ags. México.</p>
              <p className={classes.alingtextranchotel}>
                  <span className="ion-ios-telephone icon mr-5"></span>
                  <span>T. 449 172 12 67</span>
              </p>
              <p className={classes.alingtextrancho}>
                  <span className="ion-android-mail icon mr-5"></span>
                  <span>rancholasardillas@yahoo.com.mx</span><br />
                  <span>dorperaguascalientes@yahoo.com.mx</span>
              </p>
          </div>
      </div>
        </Grid>
      </Grid>
    </Container>
  );
}

ContactForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ContactForm);