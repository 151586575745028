import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Link } from 'react-router-dom';

const styles = (theme) => ({
  root: {
    maxWidth: 'none',
    background: '#fff', 
  },
  menu: {
    width: '100%',
    paddingBottom: '25px',
  },
  nav: {
    height: 'auto',
    margin: '0 auto',
    overflow: 'hidden',
    textAlign: 'left',
    fontSize: '18px',
  },
  ul: {
    float: 'left',
    listStyleType: 'none',
    padding: '0',
    position: 'relative',
    left: '50%',
  },
  li: {
    float: 'left',
    position: 'relative',
    textAlign: 'center',
    right: '50%',
    [theme.breakpoints.down('sm')]: {
      float: 'none',
      padding: '10px'
    },
  },
  link:{
    padding: '20px',
    color: '#82712c',
    textDecoration: 'none',
    font: "20px 'Open Sans', sans-serif",
    '&:hover': {
      color: '#7e1326',
    },
  },
  
});

function ProductCategoriesSmall(props) {
  const { classes } = props;

  return (
    <Container className={classes.root}>
      <div className={classes.menu}>
        <nav className={classes.nav}>
          <ul className={classes.ul}>
            <li className={classes.li}><Link className={classes.link} to="/">Inicio</Link></li>
            <li className={classes.li}><Link className={classes.link} to="/genetica">Genética</Link></li>
            <li className={classes.li}><Link className={classes.link} to="/galeria">Galería</Link></li>
            <li className={classes.li}><Link className={classes.link} to="/venta">Ejemplares en Venta</Link></li>
            <li className={classes.li}><Link className={classes.link} to="/semental">Sementales</Link></li>
            <li className={classes.li}><Link className={classes.link} to="/campeonato">Campeonatos</Link></li>
            <li className={classes.li}><Link className={classes.link} to="/kangal">Kangal</Link></li>
          </ul>
			  </nav>
      </div>
    </Container>
  );
}

ProductCategoriesSmall.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductCategoriesSmall);
