import React from 'react'

import ProductCategoriesSmall from '../../section/ProductCategoriesSmall';
import ProductValues from '../../section/ProductValues';
import RelatedBrands from '../../section/RelatedBrands';
import ContactForm from '../../section/ContactForm';
import GoogleMapsContainer from '../../section/GoogleMapsContainer';

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  heroContent: {
    padding: theme.spacing(8, 0, 0),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: "#FFF",
  },
  backgroundRancho: {
    marginTop: '5vh',
    backgroundColor:  '#ebebeb'
  },
  titleSection: {
    textTransform: 'inherit',
    fontSize: '32px',
    fontWeight: 'normal'
  },
  galeriaPreview: {
    marginBottom: '10vh'
  }
}));

export default function Home() {
  const classes = useStyles();
  
  return (
      <React.Fragment>
        <div className={classes.backgroundRancho}>
          <ProductCategoriesSmall />
          <ProductValues />
          <RelatedBrands />
          <GoogleMapsContainer />
          <ContactForm />
        </div>
      </React.Fragment>
  );
}