import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  container: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    display: 'flex',
  },
  socials:{
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    '& img': {
      height: '5vh',
      marginLeft: '4vw',
      [theme.breakpoints.down('sm')]: {
        heigth: '5vh',
      },
    },
    marginBottom: theme.spacing(3),
  },
  socialsYt:{
  }
}));

export default function AppFooter() {
  const classes = useStyles();

  return (
    <Typography component="footer" className={classes.root}>
      <Container className={classes.container}>
        <Grid container >
          <Grid item md={12} style={{textAlign: "center"}}>
            <Typography>
              Rancho Las Ardillas | © Derechos Reservados {new Date().getFullYear()}, Aguascalientes, México | <a href="https://prento.mx/" target_="_bank">PRENTO</a>  | <a href="https://consola.rancholasardillas.com/" target_="_bank">Administración</a>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Typography>
  );
}